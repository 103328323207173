import React from 'react';
import { reduxForm } from 'redux-form'
import { renderFields } from './fields/fields';
import { Button } from 'reactstrap';
import buttonArrow from '../../assets/svg/button-arrow.svg';


class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        const { handleSubmit } = this.props;

        return (
            <form onSubmit={handleSubmit}>

                {
                    renderFields(this.props.fields)
                }

                {/*<Button type="submit" color="primary">{this.props.buttonText}</Button>*/}
            </form>

        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
