import React from 'react';
import { reduxForm } from 'redux-form'

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import { renderFields } from './fields/fields';

class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;

        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size={this.props.size ? this.props.size : 'lg'}>
                <ModalHeader>{this.props.title}</ModalHeader>
                <form onSubmit={handleSubmit}>

                    <ModalBody>
                        {
                            renderFields(this.props.fields)
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" color="secondary" onClick={() => this.props.toggle()}>ODUSTANI</Button>{' '}
                        <Button type="submit" color="primary" >{this.props.buttonText}</Button>{' '}
                    </ModalFooter>
                </form>
            </Modal>
        )
    }
}

export default reduxForm({
    form: 'modalForm'  // a unique identifier for this form
})(form)
