
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText, Row, Col, Form } from 'reactstrap';


class Options extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }






    render() {

        return (
            <Row>
                {
                    this.props.values.map((item, idx) => {
                        return (
                            <Col lg={item.width.lg} sm={item.width.sm} xs={item.width.xs} className="option-wrap">
                                    <button type="button" className={this.props.value == item.value ? 'checked' : ''} onClick={() => this.props.onChange(item.value)} ></button> <span onClick={() => this.props.onChange(item.value)} className="field-after-text">{item.name}</span>
                            </Col>

                        )
                    })
                }
            </Row>
        )

    }
}

export default Options;